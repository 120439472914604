<template>
  <div class="evo-legal-home-wrapper">
    <v-row class="evo-policy-items" align="baseline" justify="start">
      <v-col cols="12" sm="12" md="4" v-for="(item, index) in items" :key="index">
        <router-link :to="item.link" target="_self" class="router-link">
          <div class="evo-item-wrapper" data-aos="fade-up">
            <div class="evo-item">
              <div class="evo-item-title evo-text-22">{{ $t(item.title) }}</div>
              <div class="evo-policy-info">
                <div class="evo-item-date evo-opensans-regular">
                  {{ item.date }}
                </div>
                <div class="evo-item-desc">{{ $t(item.desc) }}</div>
              </div>
              <div class="evo-read-more">
                <span class="evo-link evo-text-9 evo-opensans-regular">
                  {{ $t(item.linkText) }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { legalHomeMixin } from '@/mixins/legal.js';
export default {
  name: 'EvoLegalHomeSection',
  mixins: [legalHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-legal-home-wrapper {
  position: relative;
  margin-top: 56px;
  .evo-policy-items {
    margin-bottom: 32px;
    .evo-item-wrapper {
      box-shadow: 15px 15px 34px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background: #ffffff;
    }

    .evo-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-image: url('~@/assets/legal/legal-bg.png');
      background-size: 100% 66%;
      background-repeat: no-repeat;
      padding: 12px;
      height: 369px;

      & > div {
        position: relative;
      }

      .evo-item-title {
        color: #ffffff;
        margin-top: 80px;
        text-align: center;
      }

      .evo-policy-info {
        margin-bottom: 38px;
        .evo-item-date {
          color: #595f6f;
          margin-bottom: 8px;
        }

        .evo-item-desc {
          color: #1a47b0;
        }
      }

      .evo-read-more {
        position: absolute;
        bottom: 12px;
        right: 12px;

        .evo-link {
          color: #595f6f;
        }
      }
    }
  }
}
</style>
